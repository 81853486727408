<template>
  <div class="tw-flex tw-w-full">
    <Loader v-if="pageLoading" :loading="pageLoading" />
    <div v-else class="tw-flex tw-w-full tw-flex-col tw-pl-2 lg:tw-pl-20 tw-pb-20 lg:pb-0">
      <div class="tw-flex tw-flex-col div-width">
        <v-btn text max-width="5rem" class="back "
               @click="$router.push({name:'CreateSpecializeProfile',params:{step:'5'}})">
          <v-icon>mdi-chevron-left</v-icon> Back
        </v-btn>
        <label class="specialize-title tw-py-3">Preview</label>

        <label class="profile-title tw-my-5">{{createSpecializeData.job_role}}</label>
        <div class="tw-flex tw-w-full tw-flex-row tw-justify-between">
          <label class="label-title">Work Experience</label>
          <label class="edit-btn tw-cursor-pointer" @click="edit('2')">Edit</label>
        </div>
        <v-divider class="tw-mb-5" />
        <div class="tw-flex tw-flex-col tw-mb-5" v-for="(experience,index) in createSpecializeData.specialized_work_experience" :key="index">
          <h6 class="organization">{{experience.employer}}</h6>
          <label class="label-title">{{experience.job_title}},&nbsp;{{experience.job_type}}</label>
          <label class="label-title" v-if="!experience.end_date">{{processedDay(experience.start_date)}} - Till Date</label>
          <label class="label-title" v-if="experience.end_date">{{processedDay(experience.start_date)}} - {{processedDay(experience.end_date)}}</label>
        </div>
        <div class="tw-flex tw-w-full tw-flex-row tw-justify-between">
          <label class="label-title">Skills</label>
          <label class="edit-btn tw-cursor-pointer" @click="edit('3')">Edit</label>
        </div>
        <v-divider class="tw-mb-5" />
        <div class="tw-mb-5">
          <v-chip style="max-width: max-content" color="#0C8B99"
                  class="chip tw-my-2 tw-mr-5"  text-color="white" v-for="(skill,ind) in createSpecializeData.specialized_skill[0].skills" :key="ind+skill">{{skill}}</v-chip>
        </div>

        <div class="tw-flex tw-w-full tw-flex-row tw-justify-between">
          <label class="label-title">Accomplishments</label>
          <label class="edit-btn tw-cursor-pointer" @click="edit('4')">Edit</label>
        </div>
        <v-divider class="tw-mb-5" />
        <div class="tw-flex tw-flex-col tw-mb-5" v-for="(accomplishment,index) in createSpecializeData.specialized_accomplishment" :key="index+'acc'">
          <h6 class="organization">{{accomplishment.title}}</h6>
          <label class="label-title">{{accomplishment.organization}}</label>
          <label class="label-title">{{processedDay(accomplishment.date_accomplished)}}</label>
        </div>
        <div class="tw-flex tw-w-full tw-flex-row tw-justify-between">
          <label class="label-title">Certification</label>
          <label class="edit-btn tw-cursor-pointer" @click="edit('5')">Edit</label>
        </div>
        <v-divider class="tw-mb-5" />
        <div class="tw-flex tw-flex-col tw-mb-5" v-for="(certificate,index) in createSpecializeData.specialized_certification" :key="'cer'+index">
          <h6 class="organization">{{certificate.title}}</h6>
          <label class="label-title">{{certificate.organization}}</label>
          <label class="label-title">{{processedDay(certificate.date_awarded)}}</label>
        </div>
        <div class="tw-hidden md:tw-flex tw-w-full tw-justify-end tw-my-3">
          <base-button class="tw-hidden md:tw-flex " button-text="Save Profile" :loading="loading"
                       width="auto" @click="postProfile" >

          </base-button>

        </div>
      </div>
      <v-app-bar bottom absolute color="white" class="tw-w-full tw-block md:tw-hidden" height="91">
        <div class="tw-w-full tw-my-3">
          <base-button width="100%" button-text="Save Profile" @click="postProfile" :loading="loading" />
        </div>
      </v-app-bar>
    </div>
  </div>
</template>

<script>
import BaseButton from "../../reusables/BaseButton";
import moment from "moment";
import {createSpecializeProfile} from "../../../services/api/APIService";
import {mapGetters} from "vuex";
import Loader from "../../../Loader";

export default {
  name: "SpecialProfileStepSix",
  components: {Loader, BaseButton},
  data(){
    return{
      specialProfile :{},
      loading : false,
      currentStep: null,
      pageLoading : false
    }
  },
  computed:{
    ...mapGetters('createSpecialiseProfile', ['createSpecializeData']),
  },
  // watch:{
  //   currentStep:{
  //     handler :  function (){
  //         this.$store.dispatch('createSpecialiseProfile/getCreateSpecializeData')
  //         console.log(this.createSpecializeData, "From store")
  //     }
  //   },
  //   immediate : true,
  //   deep : true
  // },
  methods:{
    postProfile(){

      console.log(this.specialProfile)
      this.loading = true
        createSpecializeProfile(this.specialProfile).then(() =>{
          sessionStorage.removeItem('specialProfile')
          this.loading = false
          this.$router.push({name:'Profile'})
        })
            .catch(err=>{
              let errorKey = Object.keys(err.response.data)
              this.loading = false
              this.$displaySnackbar({
                message: err.response.data[errorKey[0]],
                success: false
              })
            })
    },
    edit(step){
      this.$router.push({name:"CreateSpecializeProfile",params:{step:step},query:{route_to_preview:'true'}})
    },

    processedDay(date_created) {
      return moment(date_created).format("MMM YYYY");
    },
  },
 async mounted() {
   // await this.$store.dispatch('createSpecialiseProfile/getCreateSpecializeData')
   //  this.pageLoading = true
   //  this.currentStep =this.$route.params.step
    this.specialProfile = this.createSpecializeData
   //  this.pageLoading = false
   if (!Object.keys(this.createSpecializeData).length){
     this.$store.commit('createSpecialiseProfile/SET_SPECIALIZE_DATA',JSON.parse(sessionStorage.getItem("specialProfile")))
   }
  }
}
</script>

<style scoped lang="scss">
.profile-title{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: #475661;
}
.label-title{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #1E323F;
}

.edit-btn{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #008EEF;
}

.organization{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #475661;
}
.chip{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
}
</style>