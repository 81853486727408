<template>
  <div class="tw-flex tw-w-full tw-flex-col tw-pl-2 lg:tw-pl-20 tw-pb-20 lg:pb-0">
    <div class="tw-flex tw-flex-col div-width">
      <v-btn text max-width="5rem" class="back "
             @click="$router.push({name:'CreateSpecializeProfile',params:{step:'3'}})">
        <v-icon>mdi-chevron-left</v-icon> Back
      </v-btn>
      <label class="specialize-title tw-py-3">Accomplishments</label>
      <div class="tw-flex tw-w-full" v-for="(data,index) in accomplishments" :key="index">
        <add-award @removeAward="removeAward" :accomplishments="accomplishments"  :data="data" :index="index" />
      </div>
      <div class="tw-flex tw-items-center tw-cursor-pointer add-experience tw-my-3" @click="addAccomplishment" >
        <vue-feather class="tw-mr-2" type="plus" style="color: #9CABB5"/>
        Add Award
      </div>

      <div class="tw-hidden md:tw-flex tw-w-full tw-justify-end tw-my-3">
        <base-button class="tw-hidden md:tw-flex tw-mr-3" button-text="Skip" outlined v-if="!$route.query.route_to_preview"
                     width="auto" @click="$router.push({name:'CreateSpecializeProfile',params:{step:'5'}})" >
        </base-button>
        <base-button class="tw-hidden md:tw-flex " :button-text="$route.query.route_to_preview ? 'Save' : 'Next'"
                     width="auto" @click="submit" >
          <template #right-icon>
            <vue-feather class="tw-ml-3" type="arrow-right" style="color: #FFFFFF"/>
          </template>
        </base-button>

      </div>

    </div>
    <v-app-bar bottom absolute color="white" class="tw-w-full tw-block md:tw-hidden" height="91">
      <div class="tw-flex tw-w-full tw-my-3 tw-flex-row tw-justify-between">
        <div>
          <base-button class="tw-flex tw-mr-5" button-text="Skip" outlined v-if="!$route.query.route_to_preview"
                          width="auto" @click="$router.push({name:'CreateSpecializeProfile',params:{step:'5'}})" ></base-button>
        </div>
          <base-button width="40%" :button-text="$route.query.route_to_preview ? 'Save': 'Next'" @click="submit" />

      </div>
    </v-app-bar>
  </div>
</template>

<script>
import AddAward from "./AddAward";
import BaseButton from "../../reusables/BaseButton";
import {mapMutations} from "vuex";
export default {
  name: "SpecializeProfileStepFour",
  components: {BaseButton, AddAward},
  data(){
    return{
      accomplishments:[],

    }
  },

  methods:{
    ...mapMutations('createSpecialiseProfile', ['SET_SPECIALIZE_DATA']),
    addAccomplishment(){
      let accomplishment = {}
      this.accomplishments.push(accomplishment)
    },
    removeAward(id){
      this.accomplishments.splice(id,1)
    },
    submit(){
      if (!this.accomplishments[0].title){
        this.$displaySnackbar({message: "You did not fill in your accomplishment details, click on Skip button to continue"})
      }
      else {
        let specialProfile = JSON.parse(sessionStorage.getItem("specialProfile"))
        specialProfile.specialized_accomplishment = this.accomplishments
        this.SET_SPECIALIZE_DATA(specialProfile)
        sessionStorage.setItem("specialProfile",JSON.stringify(specialProfile))
        this.$store.dispatch('createSpecialiseProfile/getCreateSpecializeData')
        if (this.$route.query.route_to_preview){
          this.$router.push({name:'CreateSpecializeProfile',params:{step:'6'}})
        }
        else {
          this.$router.push({name: 'CreateSpecializeProfile', params: {step: '5'}})
        }
      }
    }
  },
  mounted() {
      let specialProfile = JSON.parse(sessionStorage.getItem("specialProfile"))
      if (specialProfile.specialized_accomplishment){
        this.accomplishments = specialProfile.specialized_accomplishment
      }
      if (this.accomplishments.length === 0){
        this.addAccomplishment()
      }

  }
}
</script>

<style scoped>

</style>