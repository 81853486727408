<template>
<div class="tw-flex tw-w-full tw-flex-col tw-pl-2 lg:tw-pl-20 tw-pb-20 lg:pb-0">
  <div class="tw-flex tw-flex-col div-width">
    <v-btn text max-width="5rem" class="back "
           @click="$router.push({name:'CreateSpecializeProfile',params:{step:'2'}})">
      <v-icon>mdi-chevron-left</v-icon> Back
    </v-btn>
    <label class="specialize-title tw-py-3">Skills</label>
    <label class="err" v-if="isSelected">You have not entered your skills</label>
    <div class="tw-flex tw-w-full tw-flex-col tw-mt-5 skills">
      <v-combobox
          v-model="skills"
          color="linear-gradient(0deg, #FFFFFF, #FFFFFF)"
          label="Enter your skills"
          solo
          height="auto"
          multiple
          required
          chips
          hide-details

      >
        <template v-slot:selection="data">
          <v-chip
              v-bind="data.attrs"
              :input-value="data.skills"
              class="tw-my-5"
              color="#0C8B99"
              text-color="white"
              @click="data.select"
              id="Skill">
            <div class="tw-flex tw-items-center tw-w-full">
              <label class=""> {{ data.item }}</label>
              <div @click="handleRemoveSkill(data.item)">
                <vue-feather type="x" style="display: flex !important;" class="tw-w-4 tw-ml-2 tw-self-center"/>
              </div>
            </div>
          </v-chip>
        </template>
      </v-combobox>
    </div>
    <small class="tip">Highlight your skill set to inform recruiters of your core competencies. Press ENTER after entering a skill.</small>
    <div class="tw-hidden md:tw-flex  tw-w-full tw-justify-end tw-mt-10">
      <base-button class="tw-hidden md:tw-flex " :button-text="$route.query.route_to_preview ?'Save' : 'Next'"
                   width="auto" @click="submit" >
        <template #right-icon>
          <vue-feather class="tw-ml-3" type="arrow-right" style="color: #FFFFFF"/>
        </template>
      </base-button>

    </div>
    <v-app-bar bottom absolute color="white" class="tw-w-full tw-block md:tw-hidden" height="91">
      <div class="tw-w-full tw-my-3">
        <base-button width="100%" :button-text="$route.query.route_to_preview ? 'Save': 'Next'" @click="submit" />
      </div>
    </v-app-bar>
  </div>
</div>
</template>

<script>
import BaseButton from "../../reusables/BaseButton";
import {mapMutations} from "vuex";
export default {
  name: "SpecializeProfileStepThree",
  components: {BaseButton},
  data(){
    return{
      skills:[],
      isSelected : false
    }
  },
  watch:{
    skills: function (val) {
      if (val.length){
        this.isSelected = false
      }
    }
  },
  methods:{
    ...mapMutations('createSpecialiseProfile', ['SET_SPECIALIZE_DATA']),
    handleRemoveSkill(skill){
      this.skills= this.skills.filter(item => item !== skill)
    },
    submit(){
      if (this.skills.length){
        let mySkills = {}
        mySkills.skills = this.skills
        let arraySkillS = []
        arraySkillS.push(mySkills)
        let specialProfile = JSON.parse(sessionStorage.getItem("specialProfile"))
        specialProfile.specialized_skill = arraySkillS
        this.SET_SPECIALIZE_DATA(specialProfile)
        sessionStorage.setItem("specialProfile",JSON.stringify(specialProfile))
        this.$store.dispatch('createSpecialiseProfile/getCreateSpecializeData')
        if (this.$route.query.route_to_preview){
          this.$router.push({name:'CreateSpecializeProfile',params:{step:'6'}})
        }
        else {
          this.$router.push({name: 'CreateSpecializeProfile', params: {step: '4'}})
        }
      }
      else {
        this.isSelected = true
      }
    }
  },
  mounted() {
    let specialProfile = JSON.parse(sessionStorage.getItem("specialProfile"))
    if (specialProfile.specialized_skill){
      this.skills = specialProfile.specialized_skill[0].skills
    }
  }
}
</script>

<style scoped lang="scss">
.tip{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;
}
::v-deep .v-text-field.v-text-field--solo .v-input__append-inner, .v-text-field.v-text-field--solo .v-input__prepend-inner {
  display: none !important;
}

::v-deep .v-select__slot {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.err{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: red;
}
</style>