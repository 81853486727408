<template>
<div class="tw-flex tw-w-full tw-flex-col tw-pl-2 lg:tw-pl-20 tw-pb-20 lg:pb-0">
  <div class="tw-flex tw-flex-col div-width">
    <label class="specialize-title tw-mt-10 lg:tw-mt-0">Create a Profile</label>
    <label class="desc tw-py-2">Customize your profile to suit a specific job role and stand out from other candidates.
    </label>
     <validation-observer ref="observe" v-slot="{handleSubmit}">
       <form @submit.prevent="handleSubmit(submit)">
         <div class="tw-flex tw-flex-col tw-py-5">
           <label class="label-title tw-mb-2">Job Role</label>
           <validation-provider v-slot="{errors}" name="Job Role" rules="required">
             <v-text-field id="Job Role" solo hide-details v-model="stepOne.job_role"
                           class="label-title" placeholder="HR Manager" ></v-text-field>
             <span class="err" >{{ errors[0] }}</span>
           </validation-provider>
         </div>
         <div class="tw-flex tw-flex-col tw-py-5">
           <label class="label-title tw-mb-2">Description</label>
           <validation-provider v-slot="{errors}" name="Description" rules="required">
             <v-textarea id="Description" rows="3" solo
                         class="label-title" v-model="stepOne.description"
                         placeholder="Tell us what you did"
                         counter
                         :rules="rules"
                         :maxlength="maxlength"></v-textarea>
             <span class="err" >{{ errors[0] }}</span>
           </validation-provider>
         </div>
         <div class="tw-flex tw-flex-row tw-py-5 tw-items-center tw-justify-between">
           <div class="tw-flex tw-flex-row tw-items-end">
             <v-checkbox hide-details  v-model="stepOne.is_default" color="#8F47F4" >
               <template #label>
                 <label class="label-title tw-cursor-pointer">Make Default Profile</label>
               </template>
             </v-checkbox>
           </div>
           <base-button class="tw-hidden md:tw-flex" button-text="Next"
                        width="auto" type="submit" ></base-button>
         </div>
         <v-app-bar bottom absolute color="white" class="tw-w-full tw-block md:tw-hidden" height="91">
           <div class="tw-w-full tw-my-3">
             <base-button width="100%" button-text="Next" type="submit" />
           </div>
         </v-app-bar>
       </form>
     </validation-observer>


  </div>

</div>
</template>

<script>

import BaseButton from "../../reusables/BaseButton";
// import {mapMutations} from "vuex";
export default {
  name: "SpecializeProfileStepOne",
  components: {BaseButton},
  data(){
    return{
      stepOne:{
        is_default: false,
      },
      maxlength:200,
      rules: [v => v.length <= 200 || 'Max 200 characters'],
    }
  },
  methods:{
    submit(){
       sessionStorage.setItem("specialProfile",JSON.stringify(this.stepOne))
      this.$store.dispatch('createSpecialiseProfile/getCreateSpecializeData')
      this.$router.push({name:'CreateSpecializeProfile',params:{step:'2'}})
    }
  },
  mounted() {
   let specialProfile = JSON.parse(sessionStorage.getItem("specialProfile"))
    if (specialProfile){
       this.stepOne.job_role = specialProfile.job_role
      this.stepOne.description = specialProfile.description
      this.stepOne.is_default= specialProfile.is_default
    }
  }
}
</script>

<style scoped lang="scss">


::v-deep .v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0!important;
}
</style>
