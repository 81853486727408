<template>
  <div class="tw-flex tw-w-full tw-flex-col tw-pl-2 lg:tw-pl-20 tw-pb-20 lg:pb-0">
    <div class="tw-flex tw-flex-col div-width">
      <v-btn text max-width="5rem" class="back "
             @click="$router.push({name:'CreateSpecializeProfile',params:{step:'4'}})">
        <v-icon>mdi-chevron-left</v-icon> Back
      </v-btn>
      <label class="specialize-title tw-py-3">Certifications</label>
      <div class="tw-flex tw-w-full" v-for="(data,index) in certificates" :key="index">
        <add-award :accomplishments="certificates" @removeAward="removeAward" :data="data" :index="index" />
      </div>
      <div class="tw-flex tw-items-center tw-cursor-pointer add-experience tw-my-3" @click="addCertificate" >
        <vue-feather class="tw-mr-2" type="plus" style="color: #9CABB5"/>
        Add Certificate
      </div>
      <div class="tw-hidden md:tw-flex tw-w-full tw-justify-end tw-my-3">
        <base-button class="tw-hidden md:tw-flex tw-mr-3" button-text="Skip" outlined v-if="!$route.query.route_to_preview"
                     width="auto" @click="$router.push({name:'CreateSpecializeProfile',params:{step:'6'}})" >
        </base-button>

        <base-button class="tw-hidden md:tw-flex " :button-text="$route.query.route_to_preview ? 'Save' : 'Next'"
                     width="auto" @click="submit" >
          <template #right-icon>
            <vue-feather class="tw-ml-3" type="arrow-right" style="color: #FFFFFF"/>
          </template>
        </base-button>

      </div>
    </div>
    <v-app-bar bottom absolute color="white" class="tw-w-full tw-block md:tw-hidden" height="91">
      <div class="tw-flex tw-w-full tw-my-3 tw-flex-row tw-justify-between">
        <div><base-button class="tw-flex tw-mr-5" button-text="Skip" outlined v-if="!$route.query.route_to_preview"
                          width="auto" @click="$router.push({name:'CreateSpecializeProfile',params:{step:'6'}})" >
        </base-button></div>

        <base-button width="40%" :button-text="$route.query.route_to_preview ? 'Save': 'Next'" @click="submit" />

      </div>
    </v-app-bar>
  </div>
</template>

<script>
import AddAward from "./AddAward";
import BaseButton from "../../reusables/BaseButton";
import {mapMutations} from "vuex";

export default {
  name: "SpecializeProfileStepFour",
  components: {BaseButton, AddAward},
  data(){
    return{
      certificates:[]
    }
  },
  methods:{
    ...mapMutations('createSpecialiseProfile', ['SET_SPECIALIZE_DATA']),
    addCertificate(){
      let certificates = {}
      this.certificates.push(certificates)
    },
    removeAward(id){
      this.certificates.splice(id,1)
    },
    submit(){
      if (!this.certificates[0].title){
        this.$displaySnackbar({message: "You did not fill in your certificate details, click on Skip button to continue"})
      }
      else {
        let specialProfile = JSON.parse(sessionStorage.getItem("specialProfile"))
        specialProfile.specialized_certification = this.certificates
        // this.$store.commit('createSpecialiseProfile/SET_SPECIALIZE_DATA',JSON.parse(sessionStorage.getItem("specialProfile")))
        this.SET_SPECIALIZE_DATA(specialProfile)

        sessionStorage.setItem("specialProfile",JSON.stringify(specialProfile))
        // this.$store.dispatch('createSpecialiseProfile/getCreateSpecializeData')

        this.$router.push({name: 'CreateSpecializeProfile', params: {step: '6'}})
      }
    }
  },
  mounted() {
    let specialProfile = JSON.parse(sessionStorage.getItem("specialProfile"))
    if (specialProfile.specialized_certification){
      this.certificates = specialProfile.specialized_certification
    }
    if (this.certificates.length === 0){
      this.addCertificate()
    }
  }
}
</script>

<style scoped>

</style>