<template>
<div class="tw-w-full">

  <profile-layout>
    <template #header>
      <OnboardingHeader>
        <template #right-header-component>
          <base-button outlined button-text="Cancel" @click="cancelPosting" />
        </template>
      </OnboardingHeader>
    </template>
    <template #dashboard-sideNav>
      <specialized-side-nav />
    </template>
    <template #dashboard-content>
      <keep-alive>
        <component v-bind:is="displaySpecializedProfileComponent()"></component>
      </keep-alive>
    </template>
  </profile-layout>
   <v-dialog v-model="dialog" persistent width="500">
     <div class="card div-width tw-flex tw-py-3 tw-px-7 tw-flex-col">
       <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-py-3">
         <h6 class="title">Close Specialized Profile</h6> <vue-feather type="x" class="tw-cursor-pointer"
                                                         @click="cancelPosting"
                                                         style="color: #475661; height: 24px; width: 24px"/>
       </div>
       <label class="label tw-py-5">You are about to close Specialized Profile. Data entered will be lost, click <b>Yes</b> to continue.</label>
       <div class="tw-flex tw-w-full tw-justify-end">
         <base-button class="tw-mr-5" outlined @click="cancelPosting" button-text="Cancel"></base-button>
         <base-button button-text="Yes" @click="cancelPosting('yes')" />
       </div>
      </div>
   </v-dialog>
</div>


</template>

<script>
import ProfileLayout from "@/components/layout/ProfileLayout";
import SpecializeProfileStepOne from "../components/dashboard/components/SpecializeProfileStepOne";
import BaseButton from "../components/reusables/BaseButton";
import SpecializedSideNav from "../components/reusables/SpecializedSideNav";
import SpecializeProfileStepTwo from "../components/dashboard/components/SpecializeProfileStepTwo";
import SpecializeProfileStepThree from "../components/dashboard/components/SpecializeProfileStepThree";
import SpecializeProfileStepFour from "../components/dashboard/components/SpecializeProfileStepFour";
import SpecializeProfileStepFive from "../components/dashboard/components/SpecializeProfileStepFive";
import SpecialProfileStepSix from "../components/dashboard/components/SpecialProfileStepSix";
import OnboardingHeader from "../components/reusables/OnboardingHeader";

export default {
  name: "CreateSpecializeProfile",
  components: {ProfileLayout, OnboardingHeader, SpecializedSideNav, BaseButton},
  data(){
    return{
      dialog : false
    }
  },

  methods:{
    displaySpecializedProfileComponent(){
      let specialProfile = sessionStorage.getItem("specialProfile")
      let param = this.$route.params.step
      if (param === '1'){
        return SpecializeProfileStepOne
      }
      else if (param === '2' && specialProfile){
        return  SpecializeProfileStepTwo
      }
      else if (param === '3' && specialProfile){
        return SpecializeProfileStepThree
      }
      else if (param === '4' && specialProfile){
        return  SpecializeProfileStepFour
      }
      else if (param === '5' && specialProfile){
        return  SpecializeProfileStepFive
      }
      else if (param === '6' && specialProfile){
        return SpecialProfileStepSix
      }
      else {
        this.$router.push({name:'CreateSpecializeProfile',params:{step:'1'}})
      }
    },
    cancelPosting(type){
      this.dialog = !this.dialog
      if (type === 'yes'){
        sessionStorage.removeItem('specialProfile')
        this.$router.push({name:'Profile'})
      }
    }
  }
}
</script>

<style  lang="scss">
.specialize-title{
  font-family: IBM Plex Serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #475661;
  @media screen  and (max-width: 1024px){
    font-family: IBM Plex Serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    letter-spacing: -0.02em;
    color: #001343;
  }
}
.div-width{
  width: 500px;
  @media screen and (max-width: 1024px){
    width: 100%;
  }
}
.input-width{
  width: 48%;
}
.label-title{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;
}
.desc{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #9CABB5;
}
.err{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: red;
}
.back{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #2B1C1C;
  text-transform: none;
}
.card{
  background: #FFFFFF;
  box-shadow: 0px 24px 32px rgba(0, 61, 103, 0.16), 0px 4px 4px rgba(0, 61, 103, 0.1);
  border-radius: 12px;
}
.title{
  font-family: IBM Plex Serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #2B1C1C;
}
.label{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;
}
</style>