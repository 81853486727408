import { render, staticRenderFns } from "./AddAward.vue?vue&type=template&id=4435fdca&scoped=true&"
import script from "./AddAward.vue?vue&type=script&lang=js&"
export * from "./AddAward.vue?vue&type=script&lang=js&"
import style0 from "./AddAward.vue?vue&type=style&index=0&id=4435fdca&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4435fdca",
  null
  
)

export default component.exports