<template>
  <div class="tw-flex tw-w-full tw-flex-col" >
    <v-divider class="tw-my-5" v-if="index > 0"/>

    <div class="tw-flex tw-flex-col tw-w-full tw-my-3 ">
      <label class="label-title tw-mb-3">Title </label>
      <v-text-field  v-model="data.title" solo hide-details placeholder="Enter Award title"></v-text-field>
    </div>
    <div class="tw-flex tw-flex-col tw-w-full tw-my-3 ">
      <label class="label-title tw-mb-3">Description </label>
      <v-textarea rows="2" v-model="data.description" counter
                  :rules="rules"
                  :maxlength="maxlength" solo placeholder="Describe the Award "></v-textarea>
    </div>
    <div  class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-justify-between" v-if="$route.params.step === '4'">
      <div class="tw-flex tw-flex-col  tw-my-3 input-wid">
        <label class="label-title tw-mb-3"> Organization  </label>
        <v-text-field  v-model="data.organization" solo hide-details placeholder="Issuing Organization"></v-text-field>
      </div>
      <div class="tw-flex tw-flex-col  tw-my-3 input-wid ">
        <label class="label-title tw-mb-3">Category</label>
        <v-combobox :items="accomplishmentsItems" v-model="data.category"  solo hide-details placeholder="Award Category"></v-combobox>
      </div>
    </div>
    <div  class="tw-flex tw-w-full tw-flex-col" v-if="$route.params.step ==='5'">
      <div class="tw-flex tw-flex-col tw-w-full tw-my-3 ">
        <label class="label-title tw-mb-3">Organization  </label>
        <v-text-field  v-model="data.organization" solo hide-details placeholder="Issuing  Organization"></v-text-field>
      </div>
      <div class="tw-flex tw-flex-col tw-w-full tw-my-3 ">
        <label class="label-title tw-mb-3">Credential ID (Optional) </label>
        <v-text-field  v-model="data.Certificate_id" solo hide-details placeholder="Credential ID"></v-text-field>
      </div>
    </div>
    <div class="tw-flex tw-w-full tw-justify-between tw-flex-col lg:tw-flex-row">
      <div class="tw-flex tw-w-full tw-flex-col tw-my-3 " v-if="$route.params.step === '4'">
        <label class="label-title tw-mb-3">Date Accomplished</label>
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="data.date_accomplished"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="data.date_accomplished"
                class="label" solo hide-details
                append-icon="mdi-calendar"
                placeholder="MM/YY"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="data.date_accomplished"
              :max="maxDate"
              no-title
              scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.menu.save(data.date_accomplished)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
      <div class="tw-flex tw-w-full tw-flex-col tw-my-3 " v-if="$route.params.step === '5'">
        <label class="label-title tw-mb-3">Date Awarded</label>
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="data.date_accomplished"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="data.date_awarded"
                class="label" solo hide-details
                append-icon="mdi-calendar"
                placeholder="MM/YY"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="data.date_awarded"
              :max="maxDate"
              no-title
              scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.menu.save(data.date_awarded)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
    </div>
    <div v-if="accomplishments.length > 1" class="tw-flex tw-w-full tw-justify-end ">
      <vue-feather  style="color: #9CABB5;" type="trash-2" class="trash tw-cursor-pointer" @click="$emit('removeAward',index)" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AddAward",
  props:{
    data: Object,
    index : Number,
    accomplishments:Array
  },
  data(){
    return{
      maxDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      menu2: false,
      accomplishmentsItems:["Position", "Project", "Scholarship", "Award"],
      maxlength:200,
      rules: [v => v.length <= 200 || 'Max 200 characters'],
    }
  }
}
</script>

<style scoped lang="scss">
.trash{
  height: 24px;
  width: 24px;
  color: #9CABB5;
}
.trash:hover{
  color: black;
}
.input-wid{
  @media screen and (min-width: 1024px){
    width: 48%;
  }
  @media screen and (max-width: 1024px){
    width: 100%;
  }
}
</style>
