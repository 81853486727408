<template>
  <div class="tw-flex tw-w-full tw-flex-col">

    <div class="tw-flex tw-w-full tw-flex-row tw-items-start tw-py-5" v-if="!data.edit">
      <div class="tw-flex tw-w-3/12 lg:tw-w-2/12 tw-mr-5">
        <img :src="logo" height="64" width="64" alt="company logo"/>
      </div>
      <div class="tw-flex tw-w-9/12 lg:tw-w-10/12 tw-flex-col">
        <div class="tw-flex tw-w-full tw-justify-between tw-items-start">
          <label class="school-name">{{data.job_title}}</label>
          <vue-feather type="edit-2"
                       style="width: 15px; height: 15px; color:#475661"
                       class="tw-cursor-pointer" @click="toggleEdit()"/>
        </div>
        <label class="title-course">{{data.employer}}</label>
        <label class="school-bio">{{data.description}}</label>
        <label v-if="data.end_date" class="start-end-date">{{processedDay(data.start_date)}} - {{processedDay(data.end_date)}}</label>
        <label v-else class="start-end-date">{{processedDay(data.start_date)}} - Till date</label>
      </div>
    </div>

<div class="tw-flex tw-w-full tw-flex-col" v-if="data.edit">
  <v-divider class="tw-my-5" v-if="index > 0"/>
<div  class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-justify-between">
  <div class="tw-flex tw-flex-col  tw-my-3 ">
    <label class="label-title tw-mb-3">Employer/Company <span style="color: red">*</span> </label>
    <validation-provider v-slot="{errors}" name="Employer" rules="required">
      <v-text-field id="Employer"  v-model="data.employer" solo hide-details placeholder="Employer/Company Name"></v-text-field>
      <span class="err" >{{ errors[0] }}</span>
    </validation-provider>

  </div>
  <div class="tw-flex tw-flex-col  tw-my-3 ">
    <label class="label-title tw-mb-3">Job Title <span style="color: red">*</span> </label>
    <validation-provider v-slot="{errors}" name="Job Title" rules="required">
    <v-text-field id="Job Title" v-model="data.job_title"  solo hide-details placeholder="Software Engineer"></v-text-field>
      <span class="err" >{{ errors[0] }}</span>
    </validation-provider>
  </div>
</div>
  <div class="tw-flex tw-w-full tw-justify-between tw-flex-col lg:tw-flex-row">
    <div class="tw-flex input tw-flex-col tw-my-3 ">
      <label class="label-title tw-mb-3">Start Date <span style="color: red">*</span></label>

      <v-menu
          ref="menu"

          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="data.start_date"
          transition="scale-transition"
          offset-y
          min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <validation-provider v-slot="{errors}" name="Start Date" rules="required">
          <v-text-field
              id="Start Date"
              v-model="data.start_date"
              class="label" solo hide-details
              placeholder="YYYY/MM/DD"
              readonly
              v-bind="attrs"
              v-on="on"
          >
            <template #append>
              <vue-feather type="calendar" @click="menu = !menu" style="color: #9CABB5; cursor: pointer"/>
            </template>
          </v-text-field>
            <span class="err" >{{ errors[0] }}</span>
          </validation-provider>
        </template>
        <v-date-picker
            v-model="data.start_date"
            :max="maxDate"
            no-title
            scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
              text
              color="primary"
              @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
              text
              color="primary"
              @click="$refs.menu.save(data.start_date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>


    </div>
    <div class="tw-flex input tw-flex-col tw-my-3" v-if="!data.currently_work_here">
      <label class="label-title tw-mb-3">End Date <span style="color: red">*</span></label>

      <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          :return-value.sync="data.end_date"
          transition="scale-transition"
          offset-y
          min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <validation-provider v-slot="{errors}" name="End Date" :rules="!data.currently_work_here ? 'required':''">
          <v-text-field
              id="End Date"
              v-model="data.end_date"
              class="label" solo hide-details
              placeholder="YYYY/MM/DD"
              readonly
              v-bind="attrs"
              v-on="on"
          >
            <template #append>
              <vue-feather type="calendar" @click="menu2 = !menu2" style="color: #9CABB5; cursor: pointer"/>
            </template>
          </v-text-field>
            <span class="err" >{{ errors[0] }}</span>
          </validation-provider>
        </template>
        <v-date-picker
            v-model="data.end_date"
            :max="maxDate"
            no-title
            scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
              text
              color="primary"
              @click="menu2 = false"
          >
            Cancel
          </v-btn>
          <v-btn
              text
              color="primary"
              @click="$refs.menu2.save(data.end_date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>

    </div>
  </div>
  <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-justify-between">
    <div class="tw-flex tw-flex-col tw-w-full tw-my-3 ">
      <label class="label-title tw-mb-3">Employment Type  </label>

      <v-combobox :items="employmentTypes" id="Employment Type" v-model="data.job_type" solo hide-details placeholder="Internship"></v-combobox>

    </div>
  </div>

  <div class="tw-flex tw-flex-col tw-my-3">
    <label class="label-title tw-mb-3">Description</label>
    <v-textarea v-model="data.description" solo rows="3" counter
                :rules="rules"
                :maxlength="maxlength" />
  </div>
  <div class="tw-flex tw-w-full tw-justify-between tw-items-center">
    <v-checkbox v-model="data.currently_work_here" color="#8F47F4" >
      <template #label>
        <label class="label-title">I currently work here</label>
      </template>
    </v-checkbox>
    <v-btn text v-if="experience_history.length > 1" class="trash">
      <vue-feather type="trash-2" style="color: #9CABB5;" class="trash tw-cursor-pointer" @click="$emit('removeExperience',index)" />
    </v-btn>
  </div>


</div>
  </div>
</template>

<script>
import moment from "moment";
import logoForEducation from "@/assets/images/company.svg"

export default {
  name: "AddWorkExperience",
  props:{
    data: Object,
    index: Number,
    experience_history:Array
  },
  data(){
    return{
      maxDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      menu2: false,
      logo: logoForEducation,
      employmentTypes:["Full-Time","Part-Time","Volunteer","Internship","Freelance", "Consultant"],
      maxlength:200,
      rules: [v => v.length <= 200 || 'Max 200 characters'],
    }
  },
  methods:{
    processedDay(date_created) {
      return moment(date_created).format("MMM YYYY");
    },
    toggleEdit(){
      this.data.edit = !this.data.edit
    }
  },
  watch: {
    'data.currently_work_here': {
      handler: function (value) {
        if (value) {
          this.data.end_date = ""
        }
      },
      immediate:true
    },
  },
  computed:{
    startDateIsEmpty(){
      return !!this.data.start_date;
    }
  }
}
</script>

<style scoped lang="scss">
.trash{
  height: 24px;
  width: 24px;
  color: #9CABB5;
}
.trash:hover{
  color: black;
}
.input{
  width: 49% !important;
  @media screen  and (max-width: 1024px){
    width: 100%;
  }
}
.school-name{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #475661;
}
.title-course{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #475661;
}
.school-bio{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #9CABB5;
  width: 80%;
  @media screen and (max-width: 1024px){
    width: 100%;
  }
}

.start-end-date{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
</style>
