<template>
<div class="tw-flex tw-w-full tw-pl-16 tw-flex-col pro">
  <div v-for="(nav, index) in navs" :key="index" class="tw-flex tw-items-center tw-my-5">
    <div class="nav tw-justify-center tw-items-center tw-flex tw-mr-2" :class="Number.parseInt($route.params.step) >= nav.count? 'active-nav': 'not-active'">
      {{nav.count}}</div> <label class="label">{{nav.name}}</label>
  </div>
</div>
</template>

<script>
export default {
  name: "SpecializedSideNav",
  data(){
    return{
      navs:[
        {
          name: "TITLE",
          count : 1
        },
        {
          name: "WORK EXPERIENCE",
          count : 2
        },
        {
          name: "SKILLS",
          count : 3
        },
        {
          name: "ACCOMPLISHMENT",
          count : 4
        },
        {
          name: "CERTIFICATIONS",
          count : 5
        },
        {
          name: "SAVE PROFILE",
          count : 6
        },
      ]
    }
}
}
</script>

<style scoped lang="scss">
.label{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #142E70;
}
.active-nav{
  background: #1E323F;
}
.nav{
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #FFFFFF;

}
.not-active{
  background: #9CABB5;
}
.pro{
  @media screen and (width: 1024px){
    padding-left: 0;
  }
}
</style>
