<template>
  <div class="tw-flex tw-w-full tw-flex-col tw-pl-2 lg:tw-pl-20 tw-pb-20 lg:pb-0">
    <div class="tw-flex tw-flex-col div-width">
      <v-btn text max-width="5rem" class=" tw-flex back tw-items-center"
             @click="$router.push({name:'CreateSpecializeProfile',params:{step:'1'}})">
        <vue-feather class="tw-mr-1" style="width: 20px; height: auto" type="chevron-left" />Back
      </v-btn>
      <label class="specialize-title tw-py-3">Work Experience</label>
      <validation-observer ref="observe" v-slot="{handleSubmit}">
        <form @submit.prevent="handleSubmit(submit)">
          <div class="tw-flex tw-w-full" v-for="(data,index) in experiences" :key="index">
            <add-work-experience :experience_history="experiences" @removeExperience="removeExperience" :data="data" :index="index" />
          </div>
          <div class="tw-flex tw-items-center tw-cursor-pointer add-experience tw-my-3" @click="addExperience" >
            <vue-feather class="tw-mr-2" type="plus" style="color: #9CABB5"/>
            Add Work Experience
          </div>

          <div class="tw-hidden md:tw-flex tw-w-full tw-justify-end tw-my-3">
            <base-button class="tw-hidden md:tw-flex " :button-text="$route.query.route_to_preview ? 'Save': 'Next'"
                         width="auto" type="submit" >
              <template #right-icon>
                <vue-feather class="tw-ml-3" type="arrow-right" style="color: #FFFFFF"/>
              </template>
            </base-button>
          </div>
          <v-app-bar bottom absolute color="white" class="tw-w-full tw-block md:tw-hidden" height="91">
            <div class="tw-w-full tw-my-3">
              <base-button width="100%" :button-text="$route.query.route_to_preview ? 'Save': 'Next'" type="submit" />
            </div>
          </v-app-bar>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import AddWorkExperience from "./AddWorkExperience";
import BaseButton from "../../reusables/BaseButton";
import moment from "moment";
import {mapMutations} from "vuex";
export default {
  name: "SpecializeProfileStepTwo",
  components: {BaseButton, AddWorkExperience},
  data(){
    return{
      experiences:[],
    }
  },
  methods:{
    ...mapMutations('createSpecialiseProfile', ['SET_SPECIALIZE_DATA']),
    addExperience(){
      let experience = {}
      experience.edit = true
      this.experiences.push(experience)
    },
    removeExperience(id){
      this.experiences.splice(id,1)
    },
    submit(){

      if (this.checkData()){
        let specialProfile = JSON.parse(sessionStorage.getItem("specialProfile"))
        specialProfile.specialized_work_experience = this.experiences
        this.SET_SPECIALIZE_DATA(specialProfile)
        sessionStorage.setItem("specialProfile",JSON.stringify(specialProfile))
        this.$store.dispatch('createSpecialiseProfile/getCreateSpecializeData')
        if (this.$route.query.route_to_preview){
          this.$router.push({name:'CreateSpecializeProfile',params:{step:'6'}})
        }
      else {
          this.$router.push({name: 'CreateSpecializeProfile', params: {step: '3'}})
        }
      }
    },
    checkData(){
      let valid = false
      this.experiences.map((exp)=>{
        if (exp.end_date && moment(exp.end_date).isBefore(exp.start_date)){
          this.$displaySnackbar({message:"End Date is earlier than Start data"})
          valid = false
        }
        else {
          valid = true
        }
      })
      return  valid
    }

  },
  mounted() {
    let specialProfile = JSON.parse(sessionStorage.getItem("specialProfile"))
    if (!specialProfile.job_role){
      this.$router.push({name:'CreateSpecializeProfile',params:{step:'1'}})
    }
    else {
      if (specialProfile.specialized_work_experience){
        this.experiences =  specialProfile.specialized_work_experience
        this.experiences.forEach(function (experience){
          experience.edit = false
        })}
      if (this.experiences.length === 0){
        this.addExperience()
      }}

  }
}
</script>

<style scoped lang="scss">

.add-experience{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #475661;
  text-transform: none;
}
</style>